.top-panel-gradient-text {
  background: linear-gradient(
    90.57deg,
    #fff2db 3.2%,
    #ffdc91 53.76%,
    #ffca92 102.67%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
