.perspective {
  perspective: 1000px;
}

.form-container {
  position: relative;
  width: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.form-container.flipped {
  transform: rotateY(180deg);
}

.form-side {
  position: absolute;
  width: 100%;
  backface-visibility: hidden;
}

.front {
  transform: rotateY(0deg);
}

.back {
  transform: rotateY(180deg);
}
