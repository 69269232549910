.modal-title {
  text-align: center;
  background: linear-gradient(
    90.39deg,
    #9e211a 3.77%,
    #5a1815 47.61%,
    #5c1613 95.57%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
